export default class Page {
    constructor($el) {
        this.$el = $el;
        this.$logo = this.$el.querySelector('.js-pageLogo');
        this.logoHeight = this.$logo?.offsetHeight ?? null;

        if (this.logoHeight !== null) {
            requestAnimationFrame(this.update.bind(this));
        }
    }

    update() {
        this.$logo.style.setProperty('--scroll', Math.min(1, window.scrollY / this.logoHeight));
        requestAnimationFrame(this.update.bind(this));
    }
}
