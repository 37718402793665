import '@css/style.scss';
import posthog from 'posthog-js';

import App from '@js/App';
import Page from '@js/components/Page';

window.addEventListener('DOMContentLoaded', () => {
    window.__APP__ = new App([
        {
            component: Page,
            name: 'page',
        },
    ]);
});

// PostHog
posthog.init('phc_oEcXASpySzkgooWATEqYZpBhWNLn8c1cCTuhuEQaho', {
    api_host: 'https://eu.i.posthog.com',
    person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
});

// Vite HMR
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log('HMR');
    });
}
